import { Fragment } from "react";
import { Box, useStyleConfig } from "@chakra-ui/react";
import dynamic from "next/dynamic";

const WebContentContainer = ({ content }: { content: string }) => {
  const styles = useStyleConfig("WebContentContainer");
  return content ? (
    <Box __css={styles}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Box>
  ) : (
    <Fragment />
  );
};

export default dynamic(() => Promise.resolve(WebContentContainer), {
  ssr: false,
});
